import tools from '../../../libs/tools';
import { required, integer, minValue } from '@vuelidate/validators'

const model = {
    id: 0,
    name: "",
    company_id: 0,
    translations: {},
};

const translation = {};

export default {
    ...tools.models.apiCommons(model, translation, ""),
    modelValidation: {
        model: {
            name: {
                required
            },
            company_id: {
                required,
                integer,
                minValue: minValue(1)
            }
        }
    },
    translationValidation: {
        model: {}
    }
};
