<template>
    <div v-if='model && !model.is_null'>
        <form @submit='save' @change='formIsDirty'>
            <div class='grid p-fluid formgrid mt-6'>
                <div class='field col-12  md:col-8'>
                    <span class='p-float-label'>
                        <InputText id='name'
                                   type='text'
                                   v-model="v$.model.name.$model"
                                   :class="{'p-invalid': v$.model.name.$errors.length}"
                        />
                        <label for='name'>Nome</label>
                    </span>
                </div>
            </div>

            <div class='grid p-justify-center mt-4'>
                <div class='col-12 text-center'>
                    <SaveButton @click='save' :load='loading'/>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers('Users/CompanyTeams');
import useVuelidate from '@vuelidate/core'

import model from './model';

import Navigations from '../../../mixins/navigations';
import Notifications from '../../../mixins/notifications';

export default {
    mounted(){
        this.model = this.row;
    },
    setup () {
        return { v$: useVuelidate() }
    },
    validations () {
        return model.modelValidation;
    },
    computed: {
        ...mapGetters(['row', 'loading']),
    },
    watch: {
        row(n){
            this.model = n;
        }
    },
    methods: {
        ...mapActions(['savePlain','createPlain']),
        ...mapMutations(['formIsDirty', 'formIsClean']),
        navigateToNewItem(id, company_id){
            return this.$router.push({ path: this.$route['meta']['basePath'].replace(":company_id", company_id) + '/' + id });
        },

        async save () {
            const result = await this.v$.$validate()
            if (!result) {
                this.ShowError("Errore", "Alcuni campi del form non sono corretti");
                return
            }
            const save = (this.model.id > 0) ? this.savePlain : this.createPlain;
            const changeRoute = (this.model.id === 0);

            save(this.model).then( resp => {
                this.formIsClean();
                if (changeRoute){
                    this.navigateToNewItem(resp.id, resp.company_id).then( () => {
                        this.ShowSuccess('Ottimo', 'Team aziendale creato con successo');
                    });
                    return;
                }
                this.ShowSuccess('Ottimo', 'Team aziendale modificato con successo');

            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
    },
    mixins: [
        Navigations,
        Notifications,
    ]
};
</script>
