<template>
    <Card>
        <template #title>
            <h5 v-if='id === 0'>Nuovo record</h5>
            <h5 v-else>Modifica team "{{ teamName }}" di {{companyName}}
            </h5>
        </template>
        <template #content v-if='row'>
            <PlainForm />
        </template>
    </Card>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapGetters, mapMutations } = createNamespacedHelpers('Users/CompanyTeams');

import Factory from './model';
import Notifications from '../../../mixins/notifications';
import Navigations from '../../../mixins/navigations';
import PageGuards from '../../../mixins/guards';

import PlainForm from './Plain';
import dd_companies from '../../../mixins/dd_companies';
const commonStore = createNamespacedHelpers('Common');

export default {
    data(){
        return  {
            teamName: "",
        }
    },
    beforeRouteLeave(to, from, next){
      this.beforeRouteLeaveCheck(to, from, next);
    },
    beforeRouteUpdate(to, from, next){
        this.beforeRouteUpdateCheck(to, from, next);
    },
    mounted(){
        this.loadData(this.company_id, this.id);
    },
    computed: {
        ...mapGetters(['row', 'loading', 'dirtyForm']),
        companyName(){
            if (this.company_id > 0) {
                const company = this.getCompany(this.company_id);
                if (!company) return "";
                return company.name;
            }
            return "";
        }
    },
    methods: {
        ...mapActions(['fetchOne']),
        ...mapMutations(['setRow', 'formIsClean']),
        ...commonStore.mapMutations(['setExtraBreadcrumbs']),
        setPageBreadcrumbs(){
            if (this.company_id > 0 && this.id > 0) {
                this.setExtraBreadcrumbs([
                    { label: this.companyName, to: '/app/users/companies/'+this.company_id},
                    { label: "Teams", to: '/app/users/companies/'+this.company_id+'/company_teams'},
                    { label: 'Modifica' }
                ]);
                return;
            }
            if (this.company_id > 0) {
                this.setExtraBreadcrumbs([
                    { label: this.companyName, to: '/app/users/companies/'+this.company_id},
                    { label: "Teams", to: '/app/users/companies/'+this.company_id+'/company_teams'},
                    { label: 'Nuovo' }
                ]);
            }
        },
        loadData(company_id, id){
            if (id === 0){
                const mod = Factory.newModel(this.languages);
                mod.company_id = company_id;
                this.setRow(mod);
            } else {
                this.setPageBreadcrumbs();
                this.fetchOne({id: id, company_id: company_id}).then(res => {
                    this.teamName = res["name"];
                }).catch(err => {
                    this.ShowStandardError(err.response);
                }).finally(() => {});
            }

        }
    },
    props: {
        id: {
            default: 0,
            type: Number
        },
        company_id: {
            default: 0,
            type: Number
        }
    },
    watch: {
        id(n, o){
            if (n !== o) {
                this.loadData(this.company_id, n);
            }
        },
        dd_mapped_companies(){
            this.setPageBreadcrumbs();
        }
    },
    mixins: [
        Notifications,
        Navigations,
        PageGuards,
        dd_companies
    ],
    components: {
        PlainForm
    }
}
</script>
